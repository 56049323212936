'use client';
import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import {scrollTo} from "/app/lib/_helper";

export default function Footer() {
    return (
        <div id="footer">
            <div id={'pulse-arrows'} onClick={() => scrollTo('root')}>
                <div>
                    <Image width={18} height={22} className={'one'} src={"/images/icons/angle-up.svg"} alt={'arrow up'} />
                    <Image width={18} height={22} className={'two'} src={"/images/icons/angle-up.svg"} alt={'arrow up'} />
                    <Image width={18} height={22} className={'three'} src={"/images/icons/angle-up.svg"} alt={'arrow up'} />
                </div>
            </div>

            <div className={'flex-container flex-center'}>
        <span className={'not_clickable'}>
            BSOLUT GMBH <Image src="/images/icons/copyright.svg" alt={'Copyright'} type="image/svg+xml" width={14} height={14} /> {new Date().getFullYear()}
        </span>

                <Link href={'/imprint'}>Imprint</Link>
                <Link href={'/privacy'}>Privacy Policy</Link>
            </div>
        </div>
    )
}
