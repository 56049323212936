import React from 'react';

const scrollTo = (id, substract_nav = true, e) => {
    if (e) e.preventDefault()
    document.getElementById(id).scrollIntoView({behavior: 'smooth'});
}

const toggleView = (el) => {
    if(!el) return;

    if (el.style.display !== 'none')
        el.style.display = 'none';
    else el.style.display = 'block';
}

const URLS = {
    addOrUpdateUrlParam(name, value) {
        const url = new URL(window.location);
        if (value) url.searchParams.set(name, value);
        else url.searchParams.delete(name);

        window.history.pushState({}, '', url);
    },

    getUrlParameter(param) {
        return new URL(window.location).searchParams.get(param);
    },

    clearUrlParams() {
        window.history.replaceState(null, null, window.location.pathname);
    },
};

//===========
export {
    scrollTo,
    toggleView,
    URLS
};
//===========

