import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/lib/Footer.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-montserrat\",\"display\":\"swap\"}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-open_sans\",\"display\":\"swap\"}],\"variableName\":\"open_sans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/styles/styles.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/styles/sites.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/styles/responsive.scss");
